function quickPayButtonManager(event) {
    fetch('/api/storefront/cart', {
        credentials: 'include'
    }).then(function(response) {
        return response.json();
    }).then(function (myJson) {
        if(!$.isEmptyObject(myJson)) {
            window.checkoutData.quoteId = myJson[0].id;
            window.checkoutData.currency = myJson[0].currency.code;

            if(localStorage.getItem('quoteId') === myJson[0].id) {
                window.checkoutData.paypalConfig = JSON.parse(localStorage.getItem('paypalConfig'));
                window.checkoutData.subscription = JSON.parse(localStorage.getItem('subscription'));
                initPostMessage();
                return;
            }

            localStorage.setItem('quoteId', myJson[0].id);

            requestGetMiniCartData();
        } else {
            if(localStorage.getItem('subs_status') === "false") {
                window.checkoutData.paypalConfig = {};
                window.checkoutData.subscription = JSON.parse(localStorage.getItem('subscription'));
                initPostMessage();
                return;
            }
            requestGetMiniCartData();
            console.log('Quote object is empty');
        }
    })
}

function requestGetMiniCartData() {
    let data = {'store_hash':window.checkoutData.storeHash};

    if(window.checkoutData.quoteId) {
        data['quote_id'] = window.checkoutData.quoteId;
    } else {
        data['subscription'] = true;
    }

    $.ajax({
        dataType : "json",
        method: "POST",
        url: window.checkoutData.appUrl + '/bigcommerce/minicart/get-mini-cart-data',
        data: data,
    }).done(function (response) {
        if(response.paypalConfig) {
            window.checkoutData.paypalConfig = response.paypalConfig;
            window.checkoutData.paypalConfig.config.currency = window.checkoutData.currency;
            localStorage.setItem('paypalConfig', JSON.stringify(window.checkoutData.paypalConfig));
        }

        if(response.subscription) {
            window.checkoutData.subscription = response.subscription;
            localStorage.setItem('subscription', JSON.stringify(response.subscription));
            localStorage.setItem('subs_status', response.subscription.status);
        }

        initPostMessage();

    }).fail(function (error) {
        console.log(error);
    });
}

function initPostMessage() {
    if(window.checkoutData.paypalConfig && window.checkoutData.paypalConfig.status) {
        window.postMessage({
            'initPaypal': true,
        }, '*');
    }

    if(window.checkoutData.subscription && window.checkoutData.subscription.status) {
        window.postMessage({
            'initPaypalSubs': true,
        }, '*');
    }
}

if (window.addEventListener) {
    window.addEventListener("DOMContentLoaded", quickPayButtonManager, false);
}