import { loadScript } from "@paypal/paypal-js";
class Subscription{
    init() {
        let self = this,
            sku,
            plan,
            status,
            skuEl = '.productView-info-value[itemprop="sku"]';
        try{
            if($(skuEl).length){
                sku = $(skuEl).text();
                if(sku.length){
                    plan = self.findPlanToProduct(sku);
                    status = self.getSubscriptionStatus();
                }
                if(!$.isEmptyObject(plan) && status){
                    self.addPayPalButton(plan.plan_id);
                    self.initPayPalButtonByPlan(plan);
                }

            }
            self.initGroupProduct(skuEl);
        }catch (e) {
          console.log(e);
        }
    }

    getSubscriptionStatus(){
        try {
            if(window.checkoutData && window.checkoutData.subscription){
                return window.checkoutData.subscription.status;
            }else{
                return 0;
            }
        }catch (e) {
            return 0;
        }
    }

    findPlanToProduct(sku){
        try{
            if(window.checkoutData && window.checkoutData.subscription){
                let subscription = window.checkoutData.subscription.plan,plan = {};
                $.each(subscription, function(index, value){
                    if(index == sku){
                        plan = value;
                    }
                });
                return plan;
            }else{
                return {};
            }
        }catch (e) {
            return {};
        }
    }
    initGroupProduct(skuEl){
        let self = this,
            plan;
        $('body').on('DOMSubtreeModified', skuEl, function(){
            self.removePayPalButton();
            let sku = $(this).text();
            if(sku.length){
                plan = self.findPlanToProduct(sku);
                if(!$.isEmptyObject(plan)){
                    self.addPayPalButton(plan.plan_id);
                    self.initPayPalButtonByPlan(plan);
                }
            }
        });
    }
    initPayPalButtonByPlan(plan){
        loadScript({
            "client-id":        window.checkoutData.subscription.client_id,
            "data-partner-attribution-id": window.checkoutData.subscription.iwd_paypal_bn_code,
            "vault":            true,
            "intent":           'subscription',
            "merchant-id":      plan.merchant_id
        })
        .then((paypal) => {
            paypal.Buttons({
                style: {
                    shape: window.checkoutData.subscription.style.paypal_btn_shape,
                    color: window.checkoutData.subscription.style.paypal_btn_color,
                    height: window.checkoutData.subscription.style.height,
                    layout: 'vertical',
                    label: 'subscribe',
                    maxHeight: '40px'
                },
                createSubscription: function(data, actions) {
                    var obj = {
                        /* Creates the subscription */
                        plan_id: plan.plan_id
                    };
                    if (plan.quantity_supported == 1) {
                        if($('.form-increment input.form-input--incrementTotal').length){
                            if($('.form-increment input.form-input--incrementTotal').val() >= 1){
                                obj.quantity = $('.form-increment input.form-input--incrementTotal').val();
                            }
                        }
                    }
                    return actions.subscription.create(obj);
                },
                onApprove: function(data, actions) {
                    if(!$('.paypal-subscription-success-message').length){
                        $('.body .container .breadcrumbs').after('<div class="paypal-subscription-success-message" style="color:#fff;font-weight:600;text-align:center;background:#444;padding:10px;border-radius:10px;">PayPal Subscription was created successfully</div>');
                        setTimeout(function () {
                            $('.paypal-subscription-success-message').remove();
                        },5000)
                    }
                }
            }).render('#paypal-subscription-button');
        })
        .catch((err) => {
            console.error("failed to load the PayPal JS SDK script", err);
        });
    }
    addPayPalButton(plan_id){
        $('.productView-options').append('<div id="paypal-subscription-button" style="max-width: 300px;" data-plan-id="'+plan_id+'"></div>');
    }
    removePayPalButton(){
        $('#paypal-subscription-button').remove();
    }

}
if (window.addEventListener) {
    window.addEventListener('message',(event) => {
        if(event.data.initPaypalSubs){
            new Subscription().init();
        }
    });
}