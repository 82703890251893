jQuery(function($) {
    IWDPayPal = {
        init: function(containerId) {
            fetch('/api/storefront/cart', {
                credentials: 'include'
            }).then(function(response) {
                return response.json();
            }).then(function (cart) {
                if (window.paypal) {
                    let paypal = window.paypal,
                        clickedFundingSource = 'paypal';

                    paypal.Buttons({
                        fundingSource: window.checkoutData.paypalConfig.config.paypal_credit_status == 1 ? '' : 'paypal',
                        style: {
                            layout: 'horizontal',
                            size: 'responsive',
                            shape: window.checkoutData.paypalConfig.config.paypal_btn_shape,
                            color: window.checkoutData.paypalConfig.config.paypal_btn_color,
                            height: window.checkoutData.paypalConfig.config.height,
                            fundingicons: false,
                            tagline: false,
                        },

                        onClick: function(data)  {
                            clickedFundingSource = data.fundingSource;
                        },

                        createOrder: function(data, actions) {
                            return actions.order.create({
                                purchase_units: [{
                                    amount: {
                                        value: cart[0].cartAmount
                                    }
                                }]
                            });
                        },

                        onApprove: function(data) {
                            window.location.href = '/' + 'checkout'
                                + '?paypal_order_id=' + data.orderID + '&paypal_funding_source=' + clickedFundingSource;
                        }
                    }).render('#'+containerId);

                    if(!$('.container .page .cart-totals .iwd-paypal-credit-msg').length){
                        $('.container .page .cart-totals').append('<li class="cart-total"><div class="iwd-paypal-credit-msg" style="margin:10px 0"></div></li>');
                    }

                    if(!$('.previewCart .iwd-paypal-credit-msg').length){
                        $('.previewCartAction-viewCart').after('<div class="iwd-paypal-credit-msg" style="margin:10px 0;display: inline-block;"></div>');
                    }

                    let logoConfig = {}, amount = cart[0].baseAmount;
                    logoConfig.type = window.checkoutData.paypalConfig.config.logo_type;

                    if(logoConfig.type === 'alternative' || logoConfig.type === 'primary') {
                        logoConfig.position = window.checkoutData.paypalConfig.config.logo_position;
                    }

                    if(amount > 0 && window.checkoutData.paypalConfig.config.paypal_credit_status == 1){
                        paypal.Messages({
                            amount: amount,
                            pageType: 'cart',
                            style: {
                                layout: 'text',
                                logo: logoConfig,
                                text: {
                                    color: window.checkoutData.paypalConfig.config.text_color
                                }
                            },
                        }).render('.iwd-paypal-credit-msg');
                    }
                }
                return self;
            });
        },
    };
});

let initPaypalButton = function (event) {

    if(typeof IWDPayPal == 'undefined'){
        setTimeout(initPaypalButton, 500);
        return;
    }

    let  makeid = function(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result.toLowerCase();
    }

    if(document.getElementsByClassName('cart-actions').length){
        let paypal = document.createElement('div');
        paypal.id = 'iwd-paypal-container-'+makeid(10);
        paypal.className = 'iwd-paypal-wrapper';

        document.getElementsByClassName('cart-actions')[0].appendChild(paypal);
        IWDPayPal.init(paypal.id);

        // Add media query styles
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `
            #${paypal.id} {
                display: inline-block;
                float: right;
                max-height: 40px;
                overflow: hidden;
                margin: 0px 10px;
                border-radius: 5px;
                min-width: 320px;
            }
        
            @media (max-width: 550px) {
                #${paypal.id} {
                    min-width: auto;
                    width: 100%;
                    margin: 1rem 0;
                }
            }
        `;
        document.head.appendChild(style);
    }

    let cartInMenu = document.getElementsByClassName('previewCartAction');
    if(!cartInMenu.length){
        let target = document.querySelector('#cart-preview-dropdown');
        if(typeof(target) != "undefined" && target != null){
            let observer = new MutationObserver(function(mutations) {
                mutations.forEach(function(mutation) {
                    if(cartInMenu.length){
                        let paypal = document.createElement('div');
                        paypal.id = 'iwd-paypal-container-'+makeid(10);
                        paypal.className = 'iwd-paypal-wrapper';
                        paypal.style.display = "inline-block";
                        paypal.style.width = "100%";
                        paypal.style.margin = "10px 0";
                        if(!$('.previewCartAction .iwd-paypal-wrapper').length){
                            document.getElementsByClassName('previewCartAction')[0].appendChild(paypal);
                            IWDPayPal.init(paypal.id);
                        }
                    }
                });
            });

            var config = {childList: true}
            observer.observe(target, config);
        }
    }
};

if (window.addEventListener) {
    window.addEventListener("message", initPaypal, false);
}

function initPaypal(event) {
    if(event.data.initPaypal){
        initBasePaypalScript();
        initPaypalButton();
    }
}

function initBasePaypalScript(){
    (function (d, s, id) {
        let js, ref = d.getElementsByTagName(s)[0], merchant_id = '', enable_funding = '';
        if(window.checkoutData && window.checkoutData.paypalConfig) {
            if(window.checkoutData.paypalConfig.config.merchant_id) {
                merchant_id = '&merchant-id=' + window.checkoutData.paypalConfig.config.merchant_id;
            }
        }
        if (!d.getElementById(id)) {
            js = d.createElement(s);
            js.id = id;
            js.async = true;
            js.src = "https://www.paypal.com/sdk/js?client-id="+window.checkoutData.paypalConfig.config.client_id+"&commit=false&intent=authorize&components=buttons,messages&vault=false"+merchant_id+"&currency="+window.checkoutData.paypalConfig.config.currency+"&enable-funding=venmo,paylater";
            js.setAttribute('data-partner-attribution-id', window.checkoutData.paypalConfig.config.paypal_bn_code);
            ref.parentNode.insertBefore(js, ref);
        }
    }(document, "script", "paypal-sdk-js"));
}